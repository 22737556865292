import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TestiSwiper from "../components/swiper/swiper"
import Form from "../components/form/form"
// import { LazyLoadImage } from "react-lazy-load-image-component"
// import "react-lazy-load-image-component/src/effects/blur.css"
import research from "../components/assets/img/research.png"
import timeline from "../components/assets/img/timeline.png"
import folder from "../components/assets/img/folder.png"
import laws from "../components/assets/img/laws.png"
import twoMan from "../components/assets/img/two-man.png"
import "font-awesome/css/font-awesome.min.css"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div>
          <div className="container-fluid hero-bg">
            <div className="container">
              <div className="row hero-wrap">
                <div className="col-md-4 col-sm-12">
                  <h1>
                    Let's create a legal system that can work!
                    <br />
                  </h1>
                  <p>
                    We approach each problem with three essential:&nbsp;
                    <em>strategic thinking,&nbsp;</em>
                    <em>creative solutions,&nbsp;</em>
                    <em>proven results</em>.
                  </p>
                </div>
                <div className="col-md-4 img-hero-wrap col-sm-12">
                  <img src={laws} className="img-fluid" alt="laws" />
                </div>
                <div className="col-md-4 align-self-center open-hour-wrap">
                  <div>
                    <i className="fa fa-clock-o float-left mt-1"></i>
                    <small>Opening Hours</small>
                    <p className="text-white">Mon-Fri (Anytime)</p>
                  </div>
                  <div className="my-4">
                    <i className="fa fa-volume-control-phone float-left mt-1"></i>
                    <small>Call us now</small>
                    <p>
                      <a href="tel:(011)-681-8238">(011)-681-8238</a>
                    </p>
                  </div>
                  <div>
                    <i className="fa fa-envelope-o float-left mt-1"></i>
                    <small>Email Us</small>
                    <p>
                      <a href="mailto:laura@lnelsonparalegal.com">
                        laura@lnelsonparalegal.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="container">
              <div className="row services text-center" id="services">
                <div className="col-md-4">
                  <h4>Chronology of Facts</h4>
                  <p>
                    <img
                      src={timeline}
                      className="img-fluid py-3"
                      alt="timeline"
                    />
                  </p>
                  <p>
                    We are highly experienced, thoughtful in our role as
                    advocates, and committed to being responsive to what our
                    clients need and demand.
                  </p>
                </div>
                <div className="col-md-4">
                  <h4>Research</h4>
                  <p>
                    <img
                      src={research}
                      className="img-fluid py-3"
                      alt="research"
                    />
                  </p>
                  <p>
                    We are highly experienced, thoughtful in our role as
                    advocates, and committed to being responsive to what our
                    clients need and demand.
                  </p>
                </div>
                <div className="col-md-4">
                  <h4>Organize/Flexibility</h4>
                  <p>
                    <img src={folder} className="img-fluid py-3" alt="folder" />
                  </p>
                  <p>
                    We are highly experienced, thoughtful in our role as
                    advocates, and committed to being responsive to what our
                    clients need and demand.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="howTHY" className="container-fluid">
            <div className="container">
              <div className="row">
                <div className="col-md-12 how-help-u">
                  <div className="row my-5">
                    <div className="col-md-6 col-sm-12 text-right hhty">
                      <img src={twoMan} className="img-fluid" alt="two man" />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="align-self-center how-can-help-item">
                        <h2 className="text-white">How Can We Help?</h2>
                        <i className="fa fa-check mr-3"></i>
                        <span>Freedom to Succeed</span>
                        <p>
                          We understand the importance of organizational mission
                          and the unique challenges that nonprofit organizations
                          face.
                        </p>
                      </div>
                      <div className="align-self-center mt-4 how-can-help-item">
                        <i className="fa fa-check mr-3"></i>
                        <span>Seeing Things Differently</span>
                        <p>
                          We are highly experienced, thoughtful in our role as
                          advocates, and committed to being responsive to what
                          our clients need and demand.
                        </p>
                      </div>
                      <div className="align-self-center mt-4 how-can-help-item">
                        <i className="fa fa-check mr-3"></i>
                        <span>For Businesses</span>
                        <p>
                          We understand the importance of organizational mission
                          and the unique challenges that nonprofit organizations
                          face.
                        </p>
                      </div>
                      <div className="mt-4">
                        <Link
                          to="#contact"
                          className="btn btn-primary btn-lg"
                          role="button"
                        >
                          Contact us now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row about justify-content-center" id="about-us">
              <div className="col">
                <div className="about-txt">
                  <h2>&nbsp;What are we all about</h2>
                  <p>
                    Imagine two men facing each other, pointing past one
                    another. One is pointing at a tornado that is coming, and
                    the other at a raging fire headed towards them.
                    <br />
                  </p>
                  <p>
                    Each sees their own truth and is angry at the sight of the
                    other’s hand. Each feels that the other’s hand is “wrong.”
                    This may seem silly, but replace the tornado and fire.
                    <br />
                  </p>
                  <p>
                    Imagine two men facing each other, pointing past one
                    another. One is pointing at a tornado that is coming, and
                    the other at a raging fire headed towards them.
                    <br />
                  </p>
                  <Link
                    to="#contact"
                    className="btn btn-primary btn-lg"
                    role="button"
                  >
                    Contact us now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div id="highlight" className="container-fluid">
            <div className="container">
              <div className="jumbotron row">
                <div className="col-md-6 col-sm-12">
                  <h2 className="mb-3">
                    Aiming To Provide High-Quality Legal Consultancy
                  </h2>
                  <p className="mb-4">
                    Paralegals are an important part of our legal system working
                    at the forefront of affordable legal assistance both for
                    their employers and the public. They help create equitable
                    access to legal aid. As many clients increasingly seek less
                    costly alternatives for legal services, the demand for good
                    paralegals continues to remain robust.
                  </p>
                  <p>
                    <Link
                      className="btn btn-primary btn-lg"
                      role="button"
                      to="#contact"
                    >
                      Contact us now
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial-wrap my-5 container">
            <p id="testimonials" className="text-muted text-center mt-5">
              Testimonials
            </p>
            <h2 className="text-center text-white mb-3 pb-3">
              What Clients Say About Us
            </h2>
            <TestiSwiper slidesperview={3}>
              <div className="testi-item">
                <p className="pb-3">
                  “So strongly and metaphysically did I conceive of my situation
                  then, that while earnestly watching his motions, might plunge
                  me unmerited disaster”
                </p>
                <span className="d-block">Jack Lane</span>
                <small>Head Of Marketing</small>
              </div>
              <div className="testi-item">
                <p className="pb-3">
                  “So strongly and metaphysically did I conceive of my situation
                  then, that while earnestly watching his motions, might plunge
                  me unmerited disaster”
                </p>
                <span className="d-block">Jack Lane</span>
                <small>Head Of Marketing</small>
              </div>
              <div className="testi-item">
                <p className="pb-3">
                  “So strongly and metaphysically did I conceive of my situation
                  then, that while earnestly watching his motions, might plunge
                  me unmerited disaster”
                </p>
                <span className="d-block">Jack Lane</span>
                <small>Head Of Marketing</small>
              </div>
              <div className="testi-item">
                <p className="pb-3">
                  “So strongly and metaphysically did I conceive of my situation
                  then, that while earnestly watching his motions, might plunge
                  me unmerited disaster”
                </p>
                <span className="d-block">Jack Lane</span>
                <small>Head Of Marketing</small>
              </div>
            </TestiSwiper>
          </div>
          <div id="contact" className="container-fluid py-4">
            <div className="container">
              <p className="text-muted text-center">Contact us</p>
              <h2 className="text-center text-white mb-3">
                We'd like to hear from you
              </h2>
              <div className="row">
                <div className="col-md-6">
                  <Form
                    form_name="Contact Form"
                    form_id="5de76e44e5ac8a04f314a547"
                    method="post"
                    form_classname="bg-white p-4 m-3"
                  >
                    <div className="form-group">
                      <input
                        className="form-control mt-0"
                        name="Name"
                        type="text"
                        required
                      />
                      <label>Name</label>
                    </div>
                    <div className="form-group">
                      <input className="form-control" name="Phone" type="tel" />
                      <label>Phone (optional)</label>
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="Email"
                        type="email"
                        required
                      />
                      <label>Email</label>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="Message"
                        row="6"
                        required
                      ></textarea>
                      <label>Message</label>
                      <div className="form-group centered-captcha captcha mt-4">
                        <div className="webriq-recaptcha" />
                      </div>
                      <div className="form-group">
                        <button
                          className="btn btn-warning text-white btn-block btn-lg"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="col col-md-6 map-wrap">
                  <ul className="list-unstyled list-inline contact-item-ul">
                    <li className="list-inline-item mr-5">
                      <i className="fa fa-volume-control-phone float-left fa-lg mr-3"></i>
                      <small>Call us now</small>
                      <span className="d-block">
                        <a href="tel:(011)-681-8238">(011)-681-8238</a>
                      </span>
                    </li>
                    <li className="list-inline-item">
                      <i className="fa fa-envelope-o float-left fa-lg mr-3"></i>
                      <small>Email us</small>
                      <span className="d-block">
                        <a href="mailto:laura@lnelsonparalegal.com">
                          laura@lnelsonparalegal.com
                        </a>
                      </span>
                    </li>
                  </ul>
                  <p className="my-4">
                    <i className="fa fa-clock-o float-left mr-3 fa-lg"></i>
                    <small>Opening Hours</small>
                    <span className="d-block text-white">
                      Mon-Fri (Anytime)
                    </span>
                  </p>
                  <p className="my-4">
                    <i className="fa fa-map-marker float-left mr-3 fa-lg"></i>
                    <small>Address</small>
                    <span className="d-block text-white">
                      North America,&nbsp;North America, North America
                    </span>
                  </p>
                  <div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55662944.98508285!2d-146.63331581745985!3d31.622991329659193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b30b71698e729d%3A0x131328839761a382!2sNorth%20America!5e0!3m2!1sen!2sph!4v1573468089150!5m2!1sen!2sph"
                      width="100%"
                      height="200"
                      title="map"
                      frameborder="0"
                      className="border-0"
                      allowfullscreen=""
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
